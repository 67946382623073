@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;600&display=swap);
.profile-img {
    margin-bottom: 20px;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    width: 250px;
}

.social-link {
    margin: 10px;
    color: #666;
}

.social-link:hover {
    color: #000;
}

.papers {
    padding-left: 20px;
    list-style-image: url(/static/media/icon-file-text.09f0201e.svg);
 }
  
.papers-item {
    margin-bottom: 10px;
}
.projects {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); /* Where the magic happens */
    /* grid-auto-rows: 275px; */
    grid-gap: 20px;
}

.projects-item {
    background: #fff;
    /* border-radius: 8px; */
    border: 1px solid rgb(227, 230, 240);
    box-shadow: 0 .15rem 1rem 0 rgba(58,59,69,.15)!important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    /* height: 300px; */
    /* width: 400px; */
}

.project-content {
    margin: 10px 0 10px 0;
}

.project-thumb {
    float: left;
    max-width: 150px;
    margin-right: 10px;
}

.project-skill {
    margin-right: 10px;
}
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap'); */

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font: 300 100% Nunito, sans-serif;
  -webkit-font-smoothing: antialised;
  background: linear-gradient(#FAFAFA, #fff);
  height: 100%;
  min-height: 100vh;
  color: #444;
}

.header {
  background-color: #fff;
  border-bottom: 1px solid rgb(238, 238, 238);
  height: 65px;
  font-size: 1.2rem;
}

.brand {
  color: rgb(51, 51, 51);
  font-size: 1.5rem;
}

.menu-popup {
  background-color: #fff !important;
  max-width: 70%;
  margin-left: auto;
}

.nav-item {
  padding: 5px;
  margin-right: 10px;
}

.nav-link {
  color: rgb(51, 51, 51);
}

.nav-item:hover {
  background-color: rgb(238, 238, 238);
}

.nav-item.active {
  background-color: rgb(238, 238, 238);
}

.main-container {
  /* padding: 15px; */
  padding-top: 100px;
  padding-bottom: 25px;
  -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
          animation: fadein 1.5s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */

/* Opera < 12.1 */
