/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;600&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font: 300 100% Nunito, sans-serif;
  -webkit-font-smoothing: antialised;
  background: linear-gradient(#FAFAFA, #fff);
  height: 100%;
  min-height: 100vh;
  color: #444;
}

.header {
  background-color: #fff;
  border-bottom: 1px solid rgb(238, 238, 238);
  height: 65px;
  font-size: 1.2rem;
}

.brand {
  color: rgb(51, 51, 51);
  font-size: 1.5rem;
}

.menu-popup {
  background-color: #fff !important;
  max-width: 70%;
  margin-left: auto;
}

.nav-item {
  padding: 5px;
  margin-right: 10px;
}

.nav-link {
  color: rgb(51, 51, 51);
}

.nav-item:hover {
  background-color: rgb(238, 238, 238);
}

.nav-item.active {
  background-color: rgb(238, 238, 238);
}

.main-container {
  /* padding: 15px; */
  padding-top: 100px;
  padding-bottom: 25px;
  -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 1.5s; /* Firefox < 16 */
      -ms-animation: fadein 1.5s; /* Internet Explorer */
       -o-animation: fadein 1.5s; /* Opera < 12.1 */
          animation: fadein 1.5s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}